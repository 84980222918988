import React from "react";
import { Layout, Card, Table, Button } from "antd";
import total_booking from "../../assets/icon/total_booking.png";
import total_user from "../../assets/icon/total_users.png";
import api from "../../utils/api";
import StatusBadge from "../../components/common/StatusBadge";
import WelcomeAdminHeader from "../../components/common/WelcomeAdminHeader";

const { Content } = Layout;

const Dashboard = () => {
  const [fullName, setFullName] = React.useState("")
  const [totalUsers, setTotalUsers] = React.useState(0)
  const [totalBookings, setTotalBookings] = React.useState(0)
  const [dataBooking, setDataBooking] = React.useState([])
  const [viewAll, setViewAll] = React.useState(false)

  React.useEffect(()=>{
    checkAuthentication()
    getDashboardData()
  },[])

  const checkAuthentication = async()=> {
    const accessToken = localStorage.getItem("access_token_admin")
    if (accessToken){
      const response = await api.get("/user/me/")
      if (response?.status == 200){
        if(response?.data?.is_superuser){
          setFullName(response.data.full_name)
        }
      }
    }
  }

  const getDashboardData = async() =>{
    const response = await api.get("/admin/dashboard/")
    if(response?.status == 200){
      setTotalUsers(response?.data.total_users)
      setTotalBookings(response?.data.total_bookings)
      setDataBooking(response?.data.data_bookings)
    }
  }

  function formatMinutes(minutes) {
    if (minutes < 60) {
        return `${minutes} minutes`;
    } else {
        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;
        return `${hours} hour${hours > 1 ? 's' : ''} ${remainingMinutes > 0 ? `and ${remainingMinutes} minute${remainingMinutes > 1 ? 's' : ''}` : ''}`;
    }
  }

  function capitalizeFirstLetter(word) {
    if (!word) return '';
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }

  function formatPhoneNumber(phone) {
    const cleaned = ('' + phone).replace(/\D/g, '');
  
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    return phone;
  }

  // Data for the cards
  const cards = [
    {
      icon: <img src={total_booking} width={60} style={{borderRadius: 20}}/>,
      title: totalBookings,
      description: "Bookings",
    },
    {
      icon: <img src={total_user} width={60} style={{borderRadius: 20}}/>,
      title: totalUsers,
      description: "Total Users",
    },
  ];

  // Data for the table
  const columns = [
    {
      title: "Booking ID",
      dataIndex: "booking_id",
      key: "booking_id",
    },
    {
      title: "Client Name",
      dataIndex: "client_name",
      key: "client_name",
      render: (client_name, obj) => {
        return (
          <div style={{display: "flex", flexDirection: "column"}}>
            <span style={{fontWeight: "bold"}}>{client_name}</span>
            <span>{formatPhoneNumber(obj.phone)}</span>
          </div>
        )
      }
    },
    {
      title: "Service Type",
      dataIndex: "service_type_name",
      key: "service_type_name",
    },
    {
      title: "Session Date & Time",
      dataIndex: "session_datetime",
      key: "session_datetime",
      render: (session_datetime) => (
        <span>
          {new Date(session_datetime).toLocaleDateString("en-AU", {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
          })}
        </span>
      )
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
      render: (duration) => (
        <span>{formatMinutes(duration)}</span>
      )
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <StatusBadge status={capitalizeFirstLetter(status)} type={"booking"}/>
      ),
    },
    {
      title: "Payment Status",
      dataIndex: "payment_status",
      key: "payment_status",
      render: (paymentStatus) => (
        <StatusBadge status={capitalizeFirstLetter(paymentStatus)} type={"payment"}/>
      ),
    },
  ];


  return (
    <Layout style={{backgroundColor: "#f0f2f5", padding: "25px", height: "100vh"}}>
      <Content>
        <WelcomeAdminHeader/>
        {/* <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <h2>Hi, Welcome {fullName} 👋</h2>
          <Avatar src="https://i.pravatar.cc/300" size="large" />
        </div> */}

        {/* Cards */}
        <div style={{ display: "flex", gap: "20px", marginTop: "20px" }}>
          {cards.map((card, index) => (
            <Card
              key={index}
              style={{ flex: 1, textAlign: "center", borderRadius: "20px" }}
              bodyStyle={{ display: "flex", flexDirection: "row", padding: "10px 20px" }}
            >
              <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>{card.icon}</div>
              <div style={{display: "flex", flexDirection: "column", alignItems: "start", paddingLeft: 20}}>
                <h3 style={{ margin: "10px 0", fontSize: "24px" }}>{card.title}</h3>
                <p style={{ color: "#8c8c8c", fontSize: "20px" }}>{card.description}</p>
              </div>
            </Card>
          ))}
        </div>

        {/* Bookings Table */}
        <div style={{ marginTop: "40px", backgroundColor: "white", borderRadius: 20 }}>
          <h2 style={{paddingLeft: 20, paddingTop: 20}}>Bookings</h2>
          <Table
            dataSource={viewAll ? dataBooking: dataBooking.slice(0, 6)}
            columns={columns}
            pagination={false}
            style={{ marginTop: "20px", borderRadius: "8px", overflowY: "auto", maxHeight: "55vh" }}
          />
          <div style={{width: '100%', textAlign: "center", paddingBottom: 20}}>
          <Button type="default" style={{ marginTop: "20px" }} onClick={()=> setViewAll(!viewAll)}>
            {viewAll ? "View less" : "View all"}
          </Button>
          </div>
        </div>
      </Content>
    </Layout>
  );
};


export default Dashboard;
