import React from "react";
import { Form, Input, Button, Typography, message } from "antd";
import logo from "../../assets/logo.png"
import { useParams, useNavigate } from "react-router-dom";
import api from "../../utils/api";

const { Title, Text } = Typography;

const PasswordReset = () => {
  const [form] = Form.useForm();
  const { email, otp } = useParams();
  const navigate = useNavigate();

  const handleFinish = (values) => {
    console.log("Form Values:", values);
    // You can make an API call here to reset the password
    const data = {
        "email":  email,
        "otp": otp,
        "new_password": values.newPassword
    }
    handleChangePassword(data)
  };

  const handleChangePassword = async(data) => {
    const response = await api.post("/password-reset-change/", data)
    if (response?.status == 200){
        message.success("Change password successfully!")
        setTimeout(()=>{
            form.resetFields()
            navigate("/account/login")
        },500)
    }else{
        console.log(response)
        if (response?.data.otp == "OTP is invalid or expired."){
            message.error(`${response?.data.otp} Please re send code!`)
        }else{
            message.error(response?.data.otp)
        }
    }
  }

  return (
    <div style={styles.container}>
      <div style={styles.card}>
        {/* Logo Section */}
        <div style={styles.logo}>
          <img
            src={logo} // Replace with your logo URL
            alt="Go For It Hypnotherapy Logo"
            style={{ height: "50px" }}
          />
        </div>

        {/* Title */}
        <Title level={3} style={styles.title}>
          New Password
        </Title>
        <Text style={styles.subtitle}>Please enter new password</Text>

        {/* Form */}
        <Form
          form={form}
          layout="vertical"
          onFinish={handleFinish}
          style={styles.form}
        >
          <Form.Item
            label="New Password"
            name="newPassword"
            rules={[
              { required: true, message: "Please enter your new password!" },
              { min: 6, message: "Password must be at least 6 characters long!" },
            ]}
          >
            <Input.Password style={{padding: 10}} placeholder="Enter new password" />
          </Form.Item>

          <Form.Item
            label="Confirm Password"
            name="confirmPassword"
            dependencies={["newPassword"]}
            rules={[
              { required: true, message: "Please confirm your password!" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("Passwords do not match!")
                  );
                },
              }),
            ]}
          >
            <Input.Password style={{padding: 10}} placeholder="Confirm your password" />
          </Form.Item>

          {/* Submit Button */}
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              block
              style={styles.submitButton}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>

        {/* Footer */}
        <div style={{ position: "absolute", bottom: 20 }}>
          <Text style={{color: "#1D2C60", fontSize: 15}}>
            © 2024 Go For It Hypnotherapy, All rights reserved.
          </Text>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  card: {
    width: "400px",
    padding: "30px",
    backgroundColor: "white",
    borderRadius: "8px",
    textAlign: "center",
  },
  logo: {
    marginBottom: "20px",
  },
  title: {
    fontSize: "24px",
    marginBottom: "10px",
  },
  subtitle: {
    fontSize: "14px",
    color: "#777",
    marginBottom: "20px",
  },
  form: {
    textAlign: "left",
  },
  submitButton: {
    width: "100%",
    borderRadius: "10px",
    height: "40px",
    fontWeight: "bold",
    backgroundColor: "#006EAD"
  },
  footer: {
    fontSize: "12px",
    color: "#aaa",
    marginTop: "20px",
  },
};

export default PasswordReset;
