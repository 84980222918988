import React, { useState } from "react";
import { Table, Tag, Button, Input, Space, Tooltip, Radio, Dropdown, Menu, Drawer, message } from "antd";
import { SearchOutlined, EditOutlined, DeleteOutlined, FilterOutlined } from "@ant-design/icons";
import UserFormDrawer from "../../components/common/UserFormDrawer";
import DeleteUserModal from "../../components/common/DeleteUserModal";
import WelcomeAdminHeader from "../../components/common/WelcomeAdminHeader";
import api from "../../utils/api";
import icon_edit from "../../assets/icon/icon_edit.png"
import icon_delete from "../../assets/icon/icon_delete.png"

const Users = () => {
    const [isDrawerVisible, setDrawerVisible] = useState(false);
    const [isEditMode, setEditMode] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [isModalVisible, setModalVisible] = useState(false);
    const [totalUsers, setTotalUsers] = useState(0)
    const [dataUser, setDataUser] = useState([])
    // State to track the current filter status
    const [filterStatus, setFilterStatus] = useState("All");
    const [searchData, setSearchData] = useState("");

    // State to manage loading status
    const [loading, setLoading] = useState(false);

    const [pagination, setPagination] = useState({
      current: 1, // Current page
      pageSize: 10, // Number of records per page
      total: 0, // Total number of records
      next: null, // URL for next page of results
      previous: null, // URL for previous page of results
    });

    React.useEffect(()=>{
        getUsers()
    },[filterStatus])

    React.useEffect(()=>{
      getUsers()
    },[searchData])

    const getUsers = async() => {
        setLoading(true)
        let paramFilter = "?"
        if (filterStatus == "All"){
            paramFilter += `search=${searchData}`
        }else if (filterStatus == "Active"){
            paramFilter += `is_active=True&search=${searchData}`
        }else{
            paramFilter += `?is_active=False&search=${searchData}`
        }
        const response = await api.get("/users/"+paramFilter)
        if(response?.status == 200){
            setTotalUsers(response.data.count)
            setDataUser(response.data.results)
        }
        setLoading(false)
    }

    const openDrawer = (isEdit, user = null) => {
        setEditMode(isEdit);
        setSelectedUser(user);
        setDrawerVisible(true);
    };
    
    const closeDrawer = () => {
        setDrawerVisible(false);
    };

    const openDeleteUser = (user) => {
        setSelectedUser(user);
        setModalVisible(true);
    }

    const handleDelete = async() => {
        console.log(`User ${selectedUser} has been deleted!`);
        const response = await api.delete(`/users/${selectedUser.id}/`)
        if(response?.status == 204){
          message.success("Delete user successfully!")
        }else{
          message.error("Delete user errorr!")
        }
        setModalVisible(false);
        getUsers();
    };

    const handleSubmit = async(formData) => {
        if (isEditMode) {
          const response = await api.patch(`/users/${selectedUser.id}/`, formData)
          if (response?.status == 200){
            getUsers()
            return true
          }
        } else {
            const response = await api.post(`/users/`, formData)
            if (response?.status == 201){
              getUsers()
              return true
            }
        }
        return false
    };

    // Sample data
    const [data] = useState([
        {
            key: "1",
            userId: "001",
            name: "Dianne Russell",
            email: "Dianne.Russell@gmail.com",
            role: "Clinical Hypnotherapist",
            status: "Active",
        },
        {
            key: "2",
            userId: "002",
            name: "Albert Flores",
            email: "Albert.Flores@gmail.com",
            role: "Health and Wellness Coach",
            status: "Active",
        },
        {
            key: "3",
            userId: "003",
            name: "Cameron Williamson",
            email: "Cameron.Williamson@gmail.com",
            role: "NLP Practitioner",
            status: "Active",
        },
        {
            key: "4",
            userId: "004",
            name: "Theresa Webb",
            email: "Theresa.Webb@gmail.com",
            role: "Client Care Coordinator",
            status: "Inactive",
        },
        {
            key: "5",
            userId: "005",
            name: "Annette Black",
            email: "Annette.Black@gmail.com",
            role: "Clinical Hypnotherapist",
            status: "Inactive",
        },
    ]);

  // Filter the data based on the selected status
  const filteredData =
    filterStatus === "All"
      ? data
      : data.filter((item) => item.status === filterStatus);

  // Column configuration for the table
  const columns = [
    {
      title: "User ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "full_name",
      key: "full_name",
    },
    {
      title: "Email Address",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Status",
      dataIndex: "is_active",
      key: "is_active",
      // Render the status with colored tags
      render: (status) => (
        <Tag color={status === true ? "green" : "red"}>{status === true ? "Active" : "Locked"}</Tag>
      ),
    },
    {
      title: "",
      key: "actions",
      // Action buttons for Edit and Delete
      render: (_, record) => (
        <Space>
          <Tooltip title="Edit">
            <Button icon={<img src={icon_edit} width={14}/>} onClick={()=> openDrawer(true, record)}/>
          </Tooltip>
          <Tooltip title="Delete">
            <Button icon={<img src={icon_delete} width={14}/>} onClick={()=> openDeleteUser(record)}/>
          </Tooltip>
        </Space>
      ),
    },
  ];

  // Dropdown menu for additional filters
  const filterMenu = (
    <Menu>
      <Menu.Item key="1">Filter by Role</Menu.Item>
      <Menu.Item key="2">Filter by Email</Menu.Item>
      <Menu.Item key="3">Other Filter</Menu.Item>
    </Menu>
  );

  return (
    <div style={{ padding: "20px", background: "#f6f9fc", borderRadius: "8px", height: "100vh" }}>
        <WelcomeAdminHeader />

      <div style={{backgroundColor: "white", padding: 20, borderRadius: 10, marginTop: 20}}>
    
      <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
      <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
        <h2 style={{margin: 0, fontWeight: "bold"}}>Users</h2>
        <div style={{backgroundColor: "green", padding: "3px 10px", width: 70, borderRadius: 40, marginLeft: 20, backgroundColor: "#e8f8ed", color: "#1fa049", border: "1px solid #a8e5c0",textAlign: "center"}}>
            <span>{totalUsers}</span>
        </div>
      </div>
      {/* Add New User button and Filter dropdown */}
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button type="primary" style={{backgroundColor: "#239CD1"}} onClick={() => openDrawer(false)}>
          Add New User
        </Button>
      </div>
      </div>
      <hr style={{color: "grey", marginBottom: 15, marginTop: 15}} />
      
      <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
      {/* Filter buttons (View All, Active, Inactive) */}
      <Radio.Group
        value={filterStatus}
        onChange={(e) => setFilterStatus(e.target.value)}
        style={{ marginBottom: "20px" }}
      >
        <Radio.Button value="All">View All</Radio.Button>
        <Radio.Button value="Active">Active</Radio.Button>
        <Radio.Button value="Inactive">Inactive</Radio.Button>
      </Radio.Group>

        <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
        <div>
        {/* Search bar */}
        <Input
            placeholder="Search"
            prefix={<SearchOutlined />}
            style={{width: "200px", marginBottom: "20px" }}
            value={searchData}
            onChange={(e)=> setSearchData(e.target.value)}
        />
        </div>

        <div style={{marginLeft: 10, marginBottom: 20}}>
        <Dropdown overlay={filterMenu}>
          <Button icon={<FilterOutlined />}>Filter</Button>
        </Dropdown>
        </div>

        </div>
      </div>

      {/* Data Table */}
      <Table
        dataSource={dataUser}
        columns={columns}
        pagination={{ pageSize: 5 }}
        loading={loading}
        // bordered
      />
    </div>

    {/* Reusable Drawer Component */}
    <UserFormDrawer
        visible={isDrawerVisible}
        onClose={closeDrawer}
        onSubmit={handleSubmit}
        isEdit={isEditMode}
        selectedUser={selectedUser}
    />

    <DeleteUserModal
        visible={isModalVisible}
        onCancel={() => setModalVisible(false)}
        onConfirm={handleDelete}
        user={selectedUser}
    />
    </div>
  );
};

export default Users;
