import React from "react";
import booking_success from "../../assets/icon/booking_success.svg"
import { useLocation } from "react-router-dom";
import BannerBooking from "../../components/common/BannerBooking";

const BookingConfirmation = () => {
    const location = useLocation();
    const bookingData = location.state;
    const { service, date, start_time, end_time } = bookingData;

    // Format date and time
    const formattedDate = date.toLocaleDateString("en-AU", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
    });
    const formattedTime = `${start_time} - ${end_time}`;

    return (
    <>
        <BannerBooking />
        <div style={styles.container}>
            <div style={styles.card}>
            <img
                src={booking_success}
                alt="Confirmation"
                style={styles.image}
            />
            <h2 style={styles.title}>Your Appointment Pending Approval</h2>
            <div style={styles.info}>
                <div style={styles.row}>
                <span style={styles.label}>Service</span>
                <span style={styles.value}>{service}</span>
                </div>
                <div style={styles.row}>
                <span style={styles.label}>Date & Time</span>
                <span style={styles.value}>
                    {formattedDate}, {formattedTime}
                </span>
                </div>
            </div>
            <button style={styles.button} onClick={() => alert("Continue Clicked")}>
                Continue
            </button>
            </div>
        </div>
    </>
    );
};

// CSS-in-JS styles
const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "500px",
    marginBottom: "50px"
  },
  card: {
    backgroundColor: "#E0E0E0",
    borderRadius: "10px",
    padding: "50px 20px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    textAlign: "center",
    maxWidth: 600,
    width: "100%",
    backgroundColor: "#f5f8fc",
  },
  image: {
    width: "80px",
    marginBottom: "20px",
  },
  title: {
    fontSize: "20px",
    marginBottom: "20px",
    color: "#333",
  },
  info: {
    marginBottom: "20px",
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "10px",
    backgroundColor: "white",
    padding: "15px",
    borderRadius: "10px",
    whiteSpace: "nowrap",
    width: "auto"
  },
  label: {
    fontWeight: "bold",
    color: "#555",
    textAlign: "left",
    whiteSpace: "nowrap",
    width: "auto",
  },
  value: {
    color: "#333",
    whiteSpace: "nowrap",
    width: "auto",
  },
  button: {
    backgroundColor: "#00bcd4",
    color: "#fff",
    padding: "10px 20px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
};

export default BookingConfirmation;
