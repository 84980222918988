import React from "react";
import { Modal, Button } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const DeleteUserModal = ({ visible, onCancel, onConfirm, user }) => {
  return (
    <Modal
      title={
        <div style={{ display: "flex", alignItems: "center" }}>
          <ExclamationCircleOutlined style={{ color: "#1890ff", marginRight: 8 }} />
          Are you sure want to Delete?
        </div>
      }
      open={visible}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="delete" type="primary" danger onClick={onConfirm} style={{backgroundColor: "#239CD1"}}>
          Yes, Delete
        </Button>,
      ]}
      centered
    >
      <p>
        Once you delete this user, it will not be available named{" "}
        <strong>{`"${user?.full_name}"`}</strong>
      </p>
    </Modal>
  );
};

export default DeleteUserModal;
