import React from "react";
import AdminNavbar from "../components/common/AdminNavbar";
import { useLocation } from "react-router-dom";
import api from "../utils/api";
import { useNavigate } from "react-router-dom";
import { message } from "antd";

const AdminLayout = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate()

  React.useEffect(()=>{
    checkAuthentication()
  },[])

  const checkAuthentication = async()=> {
    const accessToken = localStorage.getItem("access_token_admin")
    if (accessToken){
      const response = await api.get("/user/me/")
      if (response?.status == 200){
        if(!response?.data?.is_superuser){
          navigate("/admin/login")
          message.error("This account does not have administrative rights. Please log in again!", 2)
        }
      }else{
        navigate("/admin/login")
      }
    }else{
      navigate("/admin/login")
    }
  }

  return (
    <div style={{display: "flex", flexDirection: "row"}}>
      {location.pathname != "/admin/login"
      &&
      <AdminNavbar />
      }
      <div style={{width: "100%", height: "100vh"}}>{children}</div>
    </div>
  );
};

export default AdminLayout;
