import React from "react";
import { Avatar } from "antd";
import api from "../../utils/api";

const WelcomeAdminHeader = ()=> {
    const [fullName, setFullName] = React.useState("")

    React.useEffect(()=>{
        checkAuthentication()
    },[])

    const checkAuthentication = async()=> {
        const accessToken = localStorage.getItem("access_token_admin")
        if (accessToken){
          const response = await api.get("/user/me/")
          if (response?.status == 200){
            if(response?.data?.is_superuser){
              setFullName(response.data.full_name)
            }
          }
        }
    }

    return(
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <h2>Hi, Welcome {fullName} 👋</h2>
          <Avatar src="https://i.pravatar.cc/300" size="large" />
        </div>
    )
}

export default WelcomeAdminHeader;