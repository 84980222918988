import React from "react";
import "../../css/common/StatusBadge.css"

const StatusBadge = ({ status, type }) => {
  const statusStyles = {
    booking: {
      Pending: {
        background: "#f0f0f5",
        color: "#5a5a5a",
        border: "1px solid #d1d1e0",
        dotColor: "#5a5a5a",
      },
      Cancelled: {
        background: "#fff5f3",
        color: "#e63900",
        border: "1px solid #ffccb3",
        dotColor: "#e63900",
      },
      Confirmed: {
        background: "#e8f8ed",
        color: "#1fa049",
        border: "1px solid #a8e5c0",
        dotColor: "#1fa049",
      },
      Completed: {
        background: "#e9f7fc",
        color: "#007bff",
        border: "1px solid #a9d7f7",
        dotColor: "#007bff",
      },
    },
    payment: {
      Completed: {
        background: "#e8f8ed",
        color: "#1fa049",
        border: "1px solid #a8e5c0",
        dotColor: "#1fa049",
      },
      Unpaid: {
        background: "#fff5f3",
        color: "#e63900",
        border: "1px solid #ffccb3",
        dotColor: "#e63900",
      },
      Pending: {
        background: "#f0f0f5",
        color: "#5a5a5a",
        border: "1px solid #d1d1e0",
        dotColor: "#5a5a5a",
      },
    },
  };

  const style = statusStyles[type]?.[status] || statusStyles["Booking"]["Pending"];

  return (
    <div
      className="status-badge"
      style={{
        backgroundColor: style.background,
        color: style.color,
        border: style.border,
      }}
    >
      <span
        className="status-dot"
        style={{
          backgroundColor: style.dotColor,
        }}
      ></span>
      {status}
    </div>
  );
};

export default StatusBadge;
