import React, { useState } from "react";
import { Form, Input, Button, Typography } from "antd";
import logo from "../../assets/logo.png"
import { useParams, useNavigate } from "react-router-dom";
import api from "../../utils/api";
import { message } from "antd";

const { Text, Link } = Typography;

const VerifyOTP = () => {
  const [form] = Form.useForm();
  const { email } = useParams();
  const [messageApi, contextHolder] = message.useMessage();
  const [isLoadingResend, setIsLoadingResend] = useState(false);
  const navigate = useNavigate();

  const onFinish = (values) => {
    const data = {
      "email": email,
      "otp": values.otp
    }
    handleVerifyOtp(data)
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleVerifyOtp = async(data) =>{
    const response = await api.post("/password-reset-verify/",data)
    console.log(response)
    if(response?.status != 200){
      form.setFields([
        {
          name: 'otp',
          errors: [response.data?.otp[0] || 'Verify Code failed'],
        },
      ]);
    }else{
      navigate(`/account/password-reset/${email}/${data.otp}`)
    }
  }

  const handleResendOTP = async() => {
    setIsLoadingResend(true)
    messageApi.open({
      type: 'loading',
      content: 'Loading send OTP...',
      duration: 0,
    });
    const data = {
      "email": email
    }
    const response = await api.post("/password-reset-request/", data)
    setIsLoadingResend(false)
    if (response.status == 200){
      messageApi.destroy()
      message.success("Resend OTP successfully!")
    }else{
      message.error("Resend code error!")
    }
  }
  return (
    <>
    {contextHolder}
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
      <div style={{ width: "400px", background: "#fff", padding: "20px", borderRadius: "8px" }}>
        <div style={{ textAlign: "center", marginBottom: "20px" }}>
          <img src={logo} alt="Logo" style={{ marginBottom: "20px" }} height={50}/>
          <h2 style={{fontWeight: "bold"}}>OTP Code</h2>
          <p style={{color: "grey"}}>Please enter your code.</p>
        </div>

        <Form
          form={form}
          name="verify-otp"
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            name="otp"
            rules={[
              { required: true, message: "Please input your OTP!" },
              { len: 4, message: "OTP must be exactly 4 digits!" },
            ]}
          >
            <Input maxLength={4} style={{ textAlign: "center", fontSize: "24px", padding: 5, fontWeight:"bold",letterSpacing: 15}} />
          </Form.Item>

          <Form.Item>
          <Button 
                type="primary" 
                htmlType="submit"  
                style={{
                    width: "100%",
                    borderRadius: "5px",
                    height: "40px",
                    fontWeight: "bold",
                    backgroundColor: "#006EAD"
                }}
            >
              Verify
            </Button>
          </Form.Item>
        </Form>

        <div style={{ textAlign: "center" }}>
            <span style={{color: "grey"}}>If you don't receive the code? </span>
            <span 
              style={{
                fontWeight: "bold",
                fontSize: 16,
                color: isLoadingResend ? "grey" : "#006EAD",
                cursor: "pointer",
                pointerEvents: isLoadingResend ? "none" : "auto"
              }}
              onClick={() => handleResendOTP()}
            >
              Resend
            </span>
        </div>

        <div style={{ textAlign: "center", marginTop: 20 }}>
            <span style={{color: "grey"}}>Back to home page account? </span>
            <Link href="/account/login" style={{fontWeight: "bold", fontSize: 16, color: "#006EAD",cursor:"pointer"}}>Sign in</Link>
        </div>

        <div style={{ position: "absolute", bottom: 20 }}>
          <Text style={{color: "#1D2C60", fontSize: 15}}>
            © 2024 Go For It Hypnotherapy, All rights reserved.
          </Text>
        </div>
      </div>
    </div>
    </>
  );
};

export default VerifyOTP;
