import React from "react";
import { Modal, Select, Form, Input } from "antd";
import api from "../../utils/api";

const EditBookingModal = ({
  visible,
  onCancel,
  onSave,
  serviceTypes,
  initialValues = {}, // Default values
}) => {
  const [form] = Form.useForm();

  // Status and Payment options
  const STATUS_CHOICES = [
    { value: "pending", label: "Pending Approval" },
    { value: "confirmed", label: "Confirmed" },
    { value: "completed", label: "Completed" },
    { value: "cancelled", label: "Cancelled" },
  ];

  const PAYMENT_STATUS_CHOICES = [
    { value: "completed", label: "Completed" },
    { value: "unpaid", label: "Unpaid" },
    { value: "pending", label: "Pending" },
  ];

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        onSave(values); // Pass the updated values to the parent
      })
      .catch((info) => {
        console.error("Validation Failed:", info);
      });
  };

  return (
    <Modal
      title="Edit Booking"
      visible={visible}
      onOk={handleOk}
      onCancel={onCancel}
      okText="Save"
      cancelText="Cancel"
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={initialValues} // Populate form with initial values
      >
        <Form.Item
          name="client_name"
          label="Client Name"
          rules={[{ required: true, message: "Please enter client name!" }]}
        >
          <Input placeholder="Enter client name" />
        </Form.Item>

        <Form.Item
          name="status"
          label="Status"
          rules={[{ required: true, message: "Please select a status!" }]}
        >
          <Select placeholder="Select Status">
            {STATUS_CHOICES.map((choice) => (
              <Select.Option key={choice.value} value={choice.value}>
                {choice.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="payment_status"
          label="Payment Status"
          rules={[
            { required: true, message: "Please select a payment status!" },
          ]}
        >
          <Select placeholder="Select Payment Status">
            {PAYMENT_STATUS_CHOICES.map((choice) => (
              <Select.Option key={choice.value} value={choice.value}>
                {choice.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name="duration" label="Duration (Minutes)" rules={[{ required: true, message: "Please enter Duration!" }]}>
          <Input placeholder="Enter duration (minutes)" />
        </Form.Item>

        <Form.Item
          name="service_type"
          label="Service Type"
          rules={[{ required: true, message: "Please select a service type!" }]}
        >
          <Select
            placeholder="Select Service Type"
            options={serviceTypes.map((type) => ({
              label: type.name, // Display name
              value: type.id, // Store ID
            }))}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditBookingModal;
