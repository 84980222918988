import React from "react";
import { Layout, Table, Space, Tooltip, Button, Radio, Dropdown, Input, Menu, Modal } from "antd";
import StatusBadge from "../../components/common/StatusBadge";
import api from "../../utils/api";
import WelcomeAdminHeader from "../../components/common/WelcomeAdminHeader";
import { EllipsisOutlined, SearchOutlined, FilterOutlined } from "@ant-design/icons";
import icon_note from "../../assets/icon/icon_note.png"
import EditBookingModal from "../../components/common/EditBookingModal";

const { Content } = Layout;

const BookingAdmin = () => {
    const [dataBooking, setDataBooking] = React.useState([]);
    const [totalBookings, setTotalBookings] = React.useState(0);
    const [filterStatus, setFilterStatus] = React.useState("All");
    const [searchData, setSearchData] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [noteMessage, setNoteMessage] = React.useState(false);
    const [isShowModalNote, setIsShowModalNote] = React.useState(false);

    const [isModalVisible, setIsModalVisible] = React.useState(false);
    const [selectedBooking, setSelectedBooking] = React.useState({});

    const [serviceTypes, setServiceTypes] = React.useState([]);

    React.useEffect(()=>{
      getSerivceType()
    },[])
  
    const getSerivceType = async() =>{
      const response = await api.get("/service-type/")
      if(response?.status == 200){
          setServiceTypes(response?.data.results)
      }
    }
    
    React.useEffect(()=>{
        getDataBooking()
    },[filterStatus, searchData])

    const getDataBooking = async() => {
        setLoading(true)
        let paramFilter = "?"
        if (filterStatus == "All"){
            paramFilter += `search=${searchData}`
        }else if (filterStatus){
            paramFilter += `status=${filterStatus}&search=${searchData}`
        }else{
            paramFilter += `status=${filterStatus}&search=${searchData}`
        }
        const response = await api.get(`/bookings/${paramFilter}`)
        if (response?.status == 200){
            setDataBooking(response?.data.results)
            setTotalBookings(response?.data.count)
        }
        setLoading(false)
    }

    function formatPhoneNumber(phone) {
        const cleaned = ('' + phone).replace(/\D/g, '');
      
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
          return `(${match[1]}) ${match[2]}-${match[3]}`;
        }
        return phone;
    }

    function formatMinutes(minutes) {
        if (minutes < 60) {
            return `${minutes} minutes`;
        } else {
            const hours = Math.floor(minutes / 60);
            const remainingMinutes = minutes % 60;
            return `${hours} hour${hours > 1 ? 's' : ''} ${remainingMinutes > 0 ? `and ${remainingMinutes} minute${remainingMinutes > 1 ? 's' : ''}` : ''}`;
        }
    }

    function capitalizeFirstLetter(word) {
        if (!word) return '';
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }

    const handleSaveEdit = (updatedValues) => {
        console.log("Updated Booking:", updatedValues);
        setBookingData(updatedValues); // Update the booking data in the state
        setIsModalVisible(false);
      };
    
    const handleCancelEdit = () => {
        setIsModalVisible(false);
    };

    // Data for the table
    const columns = [
        {
            title: "Booking ID",
            dataIndex: "booking_id",
            key: "booking_id",
        },
        {
            title: "Client Name",
            dataIndex: "client_name",
            key: "client_name",
            render: (client_name, obj) => {
            return (
                <div style={{display: "flex", flexDirection: "column"}}>
                <span style={{fontWeight: "bold"}}>{client_name}</span>
                <span>{formatPhoneNumber(obj.phone)}</span>
                </div>
            )
            }
        },
        {
            title: "Service Type",
            dataIndex: "service_type_name",
            key: "service_type_name",
        },
        {
            title: "Session Date & Time",
            dataIndex: "session_datetime",
            key: "session_datetime",
            render: (session_datetime) => (
            <span>
                {new Date(session_datetime).toLocaleDateString("en-AU", {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
                })}
            </span>
            )
        },
        {
            title: "Duration",
            dataIndex: "duration",
            key: "duration",
            render: (duration) => (
            <span>{formatMinutes(duration)}</span>
            )
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (status) => (
            <StatusBadge status={capitalizeFirstLetter(status)} type={"booking"}/>
            ),
        },
        {
            title: "Payment Status",
            dataIndex: "payment_status",
            key: "payment_status",
            render: (paymentStatus) => (
            <StatusBadge status={capitalizeFirstLetter(paymentStatus)} type={"payment"}/>
            ),
        },
        {
            title: "",
            key: "actions",
            // Action buttons for Edit and Delete
            render: (_, record) => (
              <Space>
                <Tooltip title="Note">
                  <Button icon={<img src={icon_note} width={14}/>} onClick={()=>{setIsShowModalNote(true); setNoteMessage(record.message)}}/>
                </Tooltip>
                <Tooltip title="More">
                  <Button icon={<EllipsisOutlined />}
                    onClick={()=>{
                        setSelectedBooking(record)
                        setIsModalVisible(true)
                    }}
                  />
                </Tooltip>
              </Space>
            ),
        },
    ];

    // Dropdown menu for additional filters
    const filterMenu = (
        <Menu>
        <Menu.Item key="1">Filter by Role</Menu.Item>
        <Menu.Item key="2">Filter by Email</Menu.Item>
        <Menu.Item key="3">Other Filter</Menu.Item>
        </Menu>
    );

    return(
    <Layout style={{backgroundColor: "#f0f2f5", padding: "25px", height: "100vh"}}>
      <Content>
        <WelcomeAdminHeader />
        <div style={{backgroundColor: "white", padding: 20, borderRadius: 10, marginTop: 20}}>
        
            <div style={{display: "flex"}}>
                <h2 style={{margin: 0, fontWeight: "bold"}}>Bookings</h2>
                <div style={{backgroundColor: "green", padding: "3px 10px", width: 70, borderRadius: 40, marginLeft: 20, backgroundColor: "#e8f8ed", color: "#1fa049", border: "1px solid #a8e5c0",textAlign: "center"}}>
                    <span>{totalBookings}</span>
                </div>
            </div>
            <hr style={{color: "grey", marginBottom: 15, marginTop: 15}} />

            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
             {/* Filter buttons (View All, Active, Inactive) */}
            <Radio.Group
                value={filterStatus}
                onChange={(e) => setFilterStatus(e.target.value)}
            >
                <Radio.Button value="All">View All</Radio.Button>
                <Radio.Button value="peding">Pending Approval</Radio.Button>
                <Radio.Button value="confirmed">Confirmed</Radio.Button>
                <Radio.Button value="completed">Completed</Radio.Button>
                <Radio.Button value="canceled">Canceled</Radio.Button>
            </Radio.Group>
            
            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                <div>
                    {/* Search bar */}
                    <Input
                        placeholder="Search"
                        prefix={<SearchOutlined />}
                        style={{width: "200px" }}
                        value={searchData}
                        onChange={(e)=> setSearchData(e.target.value)}
                    />
                </div>

                <div style={{marginLeft: 10}}>
                <Dropdown overlay={filterMenu}>
                    <Button icon={<FilterOutlined />}>Filter</Button>
                </Dropdown>
            </div>
            </div>
        </div>
            <Table
                dataSource={dataBooking}
                columns={columns}
                pagination={true}
                style={{ marginTop: "20px", borderRadius: "8px", overflowY: "auto", maxHeight: "55vh" }}
                loading={loading}
            />
        </div>
      </Content>
      <Modal
            title={"Note"}
            open={isShowModalNote}
            onCancel={()=> setIsShowModalNote(false)}
            footer={[
            <Button key="cancel" onClick={()=> setIsShowModalNote(false)}>
                Close
            </Button>
            ]}
        >
            {noteMessage}
        </Modal>

        <EditBookingModal
            visible={isModalVisible}
            onCancel={handleCancelEdit}
            onSave={handleSaveEdit}
            initialValues={selectedBooking} // Pass current booking data as initial values
            serviceTypes={serviceTypes}
        />
    </Layout>
    )
}

export default BookingAdmin;