import React from "react";
import { Drawer, Form, Input, Checkbox, Row, Col, Button, Space, message } from "antd";

const UserFormDrawer = ({ visible, onClose, onSubmit, selectedUser, isEdit }) => {
  // Form instance for handling form actions
  const [form] = Form.useForm();

  // Handle form submission
  const handleFinish = async(values) => {
    const response = await onSubmit(values);
    if(response == true){
      message.success(`${isEdit ? "User updated" : "User created"} successfully!`);
      onClose();
      if(isEdit == false){
        form.resetFields();
      }
    }else{
      message.error(`${isEdit ? "User updated" : "User created"} error!`);
    }
  };

  React.useEffect(() => {
    if (selectedUser) {
      form.setFieldsValue(selectedUser);
    } else {
      form.resetFields();
    }
  }, [selectedUser, form]);

  return (
    <Drawer
      title={isEdit ? "Edit User" : "Create New User"}
      width={900}
      onClose={onClose}
      visible={visible}
      footer={
        <div style={{ textAlign: "right" }}>
          <Button onClick={onClose} style={{ marginRight: 8 }}>
            Cancel
          </Button>
          <Button type="primary" onClick={() => form.submit()}>
            {isEdit ? "Update" : "Save"}
          </Button>
        </div>
      }
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleFinish}
      >
        {/* Full Name and Username */}
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="full_name"
              label="Full Name"
              rules={[{ required: true, message: "Full Name is required" }]}
            >
              <Input placeholder="Full Name" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="username"
              label="Username"
              rules={[{ required: true, message: "Username is required" }]}
            >
              <Input placeholder="Username" />
            </Form.Item>
          </Col>
        </Row>

        {/* Email Address and Phone Number */}
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="email"
              label="Email Address"
              rules={[
                { required: true, message: "Email Address is required" },
                { type: "email", message: "Please enter a valid email" },
              ]}
            >
              <Input placeholder="Email Address" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="phone"
              label="Phone Number"
              rules={[{ required: true, message: "Phone Number is required" }]}
            >
              <Input placeholder="Phone Number" />
            </Form.Item>
          </Col>
        </Row>

        {!isEdit &&
        <>
        {/* Password and Confirm Password */}
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="password"
              label="Password"
              rules={[{ required: !isEdit, message: "Password is required" }]}
            >
              <Input.Password placeholder="Password" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="confirmPassword"
              label="Confirm Password"
              dependencies={["password"]}
              rules={[
                { required: !isEdit, message: "Confirm Password is required" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("Passwords do not match!"));
                  },
                }),
              ]}
            >
              <Input.Password placeholder="Confirm Password" />
            </Form.Item>
          </Col>
        </Row>
        </>
        }

        {/* Role Selection */}
        {/* <Form.Item name="roles" label="Choose Role">
          <Checkbox.Group>
            <Row>
              <Col span={12}>
                <Checkbox value="Clinical Hypnotherapist">Clinical Hypnotherapist</Checkbox>
              </Col>
              <Col span={12}>
                <Checkbox value="Client Care Coordinator">Client Care Coordinator</Checkbox>
              </Col>
              <Col span={12}>
                <Checkbox value="NLP Practitioner">NLP Practitioner</Checkbox>
              </Col>
              <Col span={12}>
                <Checkbox value="Health and Wellness Coach">Health and Wellness Coach</Checkbox>
              </Col>
            </Row>
          </Checkbox.Group>
        </Form.Item> */}

        {/* Status Selection */}
        <Form.Item 
          name="is_active" 
          label="Status" 
          valuePropName="checked"
        >
          <Checkbox>Active</Checkbox>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default UserFormDrawer;
