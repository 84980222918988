import React from "react";
import { Layout, Menu, Input } from "antd";
import logo from "../../assets/logo_admin.png"
import { NavLink, useLocation } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";
import "../../css/common/AdminNavbar.css"
import { useNavigate } from "react-router-dom";
import icon_dashboard from "../../assets/icon/icon_dashboard.png"
import icon_profile from "../../assets/icon/icon_profile.png"
import icon_blog from "../../assets/icon/icon_blog.png"
import icon_setting from "../../assets/icon/icon_setting.png"
import icon_booking from "../../assets/icon/icon_booking.png"
import icon_users from "../../assets/icon/icon_users.png"

const { Sider } = Layout;

const Navbar = () => {
  const navigate = useNavigate()
  const location = useLocation()

  return (
    <Sider width={250} style={{ height: "100vh", backgroundColor: "#1b2331", paddingInline: 10 }}>
      <div style={{ display: "flex", justifyContent: "center", padding: "20px 0" }}>
        <img src={logo} width={250}/>
      </div>
      <div
        style={{
          padding: "10px",
          backgroundColor: "#1b2331",
          marginBottom: 20
        }}
      >
        <Input
          placeholder="Search"
          prefix={<SearchOutlined style={{ color: "#ffffff", fontSize: "18px" }} />}
          style={{
            borderRadius: "8px",
            border: "1px solid #39424e",
            backgroundColor: "#1b2331",
            color: "#ffffff",
            height: "40px",
          }}
          className="custom-search-input"
        />
      </div>
      <Menu
        theme="dark"
        mode="inline"
        style={{
          backgroundColor: "#1b2331",
          color: "#ffffff",
        }}
        // defaultSelectedKeys={["1"]}
        // selectedKeys={["1"]}
        selectedKeys={[location.pathname]}
      >
        <Menu.Item key="/admin/dashboard" icon={<img src={icon_dashboard} width={24}/>}>
        <NavLink to={"/admin/dashboard"}>
          Dashboard
        </NavLink>
        </Menu.Item>
        <Menu.Item key="2" icon={<img src={icon_blog} width={24}/>}>
          Blog
        </Menu.Item>
        <Menu.Item key="/admin/users" icon={<img src={icon_users} width={24}/>}>
        <NavLink to={"/admin/users"}>
          Users
        </NavLink>
        </Menu.Item>
        <Menu.Item key="/admin/bookings" icon={<img src={icon_booking} width={24}/>}>
        <NavLink to={"/admin/bookings"}>
          Booking
        </NavLink>
        </Menu.Item>
        <Menu.Item key="5" icon={<img src={icon_setting} width={24}/>}>
          Settings
        </Menu.Item>
        <Menu.Item key="6" icon={<img src={icon_profile} width={24}/>}>
          Profile
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

export default Navbar;
