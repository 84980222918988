import React from "react";

const CommingSoon = () => {
  return (
    <div style={{height:"42vh", textAlign: "center"}}>
        <h1>This page Comming Soon !</h1>
    </div>
  );
};

export default CommingSoon;
