import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import UserLayout from "./layouts/UserLayout";
import AdminLayout from "./layouts/AdminLayout";
import AccountLayout from "./layouts/AccountLayout";

import Home from "./pages/user/Home";
import CommingSoon from "./pages/user/CommingSoon";
import Login from "./pages/account/Login";
import Signup from "./pages/account/SignUp";
import RecoverPassword from "./pages/account/RecoverPassword";
import VerifyOTP from "./pages/account/VerifyOTP";

import Booking from "./pages/user/Booking";
import ScrollToTop from "./components/common/ScrollTop";
import Dashboard from "./pages/admin/Dashboard";
import PasswordReset from "./pages/account/PasswordReset";
import BookingConfirmation from "./pages/user/BookingConfirmation";
import LoginAdmin from "./pages/admin/LoginAdmin";
import Users from "./pages/admin/Users";
import BookingAdmin from "./pages/admin/BookingAdmin";

// import Dashboard from "./pages/admin/Dashboard";
// import Settings from "./pages/admin/Settings";
// import NotFound from "./pages/NotFound";

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        {/* Route User */}
        <Route
          path="*"
          element={
            <UserLayout>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/services" element={<CommingSoon />} />
                <Route path="/pricing" element={<CommingSoon />} />
                <Route path="/contact" element={<CommingSoon />} />
                <Route path="/booking" element={<Booking />} />
                <Route path="/booking-success" element={<BookingConfirmation />} />
              </Routes>
            </UserLayout>
          }
        />

        {/* Route Account */}
        <Route
          path="/account/*"
          element={
            <AccountLayout>
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/reset-password" element={<RecoverPassword />} />
                <Route path="/verify-otp/:email" element={<VerifyOTP />} />
                <Route path="/password-reset/:email/:otp" element={<PasswordReset />} />
              </Routes>
            </AccountLayout>
          }
        />

        {/* Route Admin */}
        <Route
          path="/admin/*"
          element={
            <AdminLayout>
              <Routes>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/login" element={<LoginAdmin/>} />
                <Route path="/users" element={<Users />} />
                <Route path="/bookings" element={<BookingAdmin />} />
              </Routes>
            </AdminLayout>
          }
        />

        {/* Route Not Found */}
        {/* <Route path="*" element={<NotFound />} /> */}
      </Routes>
    </Router>
  );
};

export default App;
