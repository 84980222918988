import React from "react";
import BannerBooking from "../../components/common/BannerBooking";
import { Form, Input, Button, Select, DatePicker, Checkbox, message, TimePicker } from 'antd';
import api from "../../utils/api";
import { useNavigate } from "react-router-dom";

const Booking = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();

    const [serviceTypes, setServiceTypes] = React.useState([])

    React.useEffect(()=>{
        getSerivceType()
    },[])

    const getSerivceType = async() =>{
        const response = await api.get("/service-type/")
        if(response?.status == 200){
            const data = response.data.results
            setServiceTypes(data)
        }
    }
    const onFinish = (values) => {
        const startTime = form.getFieldValue('start_time');
        const endTime = form.getFieldValue('end_time');

        if (!startTime || !endTime) {
            message.error('Please select both start and end times!');
            return;
        }

        const duration = endTime.diff(startTime, 'minutes'); // Tính phút

        if (duration <= 0) {
            message.error('End time must be after start time!');
        } else {
            message.success(`Duration: ${Math.floor(duration / 60)} hours and ${duration % 60} minutes`);
        }
        // Here you can handle form submission (values contains all form data)
        // message.success('Form submitted successfully!');
        handleBooking(values)
    };

    const handleBooking = async(values) => {
        const data = {
            service_type: values.service,
            session_datetime: values.date,
            start_time: formatTime(values.start_time),
            end_time: formatTime(values.end_time),
            message: values.message,
            duration: calculateDuration(values.start_time, values.end_time),
            client_name: values.clientName,
            phone: values.phone
        }
        const response = await api.post("/booking-for-user/", data)
        if (response?.status == 201){
            form.resetFields()
            const service = serviceTypes.find(item => item.id === values.service);
            const serviceName = service ? service.name : null;
            const bookingData = {
                start_time: formatTime(values.start_time),
                end_time: formatTime(values.end_time),
                date: new Date(values.date),
                service: serviceName
            }

            navigate("/booking-success", { state: bookingData });
        }
    }

    const formatTime = (isoString) => {
        const date = new Date(isoString);
        
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
    
        return `${hours}:${minutes}`;
    };

    const calculateDuration = (start, end) => {
        const startDate = new Date(start);
        const endDate = new Date(end);
        return Math.round((endDate - startDate) / (1000 * 60));
    };

    return(
        <div>
            <BannerBooking  />
            <div style={{maxWidth: 1000, margin: '0 auto', padding: '20px', backgroundColor: 'rgb(245, 245, 245)', borderRadius: '8px', boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', marginBottom: 50 }}>
                <Form
                    name="booking_form"
                    onFinish={onFinish}
                    initialValues={{ remember: true }}
                    layout="vertical"
                    form={form}
                >
                    <Form.Item
                        name="service"
                        label="Choose Service"
                        rules={[{ required: true, message: 'Please select a service!' }]}
                    >
                    <Select defaultValue="Select">
                        {serviceTypes.map(e => 
                            <Option value={e.id}>{e.name}</Option>
                        )}
                    </Select>
                    </Form.Item>

                    <Form.Item
                    name="date"
                    label="Choose Date & Time"
                    rules={[{ required: true, message: 'Please select a date!' }]}
                    >
                    <DatePicker format="DD/MM/YYYY" style={{ width: '100%' }} />
                    </Form.Item>
                    
                    <div style={{ display: 'flex', gap: '16px' }}>
                    <Form.Item
                        name="start_time"
                        label="Start Time"
                        rules={[
                            { required: true, message: 'Please select a start time!' },
                        ]}
                    >
                        <TimePicker format="HH:mm" style={{ width: '100%' }} />
                    </Form.Item>

                    <Form.Item
                        name="end_time"
                        label="End Time"
                        rules={[
                            { required: true, message: 'Please select an end time!' },
                        ]}
                    >
                        <TimePicker format="HH:mm" style={{ width: '100%' }} />
                    </Form.Item>
                    </div>

                    <Form.Item
                        name="message"
                        label="Message"
                        rules={[{ required: true, message: 'Please leave a message!' }]}
                    >
                        <Input.TextArea style={{height: 180}} placeholder="Leave us a message..." />
                    </Form.Item>
                    
                    <Form.Item
                        name="clientName"
                        label="Full name"
                        rules={[{ required: true, message: 'Please input your name!' }]}
                    >
                    <Input
                        style={{ width: '100%' }}
                        placeholder="Full Name"
                    />
                    </Form.Item>

                    <Form.Item
                    name="phone"
                    label="Phone number"
                    rules={[{ required: true, message: 'Please input your phone number!' }]}
                    >
                    <Input
                        addonBefore="AU +"
                        style={{ width: '100%' }}
                        placeholder="+61 555 000-0000"
                    />
                    </Form.Item>

                    <Form.Item name="privacy" valuePropName="checked" noStyle>
                    <Checkbox>
                        You agree to our friendly <a href="#">privacy policy</a>.
                    </Checkbox>
                    </Form.Item>

                    <Form.Item>
                    <Button type="primary" htmlType="submit" style={{ width: '100%', borderRadius: 20, marginTop: 10, padding: 20, fontWeight: "bold", backgroundColor: "#38B0CD" }}>
                        Submit
                    </Button>
                    </Form.Item>
                </Form>
                </div>
        </div>
    )

}

export default Booking;